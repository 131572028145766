<template>
  <div>
    <b-loading v-model="isLoading" :is-full-page="false"></b-loading>
    <p style="text-align: right" class="is-size-4 has-text-grey-dark">
      {{ $t('Resource Location Assigner') }}
    </p>
    <keep-alive>
      <result-tables
        v-if="successList.length > 0 || errorList.length > 0"
        :success-data="successList"
        :error-data="errorList"
        :success-columns="successColumns"
        :error-columns="errorColumns"
        default-sort-column="generalError"
        default-sort-direction="asc"
      />
    </keep-alive>
    <div class="buttons">
      <b-button type="is-info" @click="showHelp = !showHelp">
        {{ showHelp ? $t('Hide guide') : $t('Show guide') }}
      </b-button>
    </div>

    <b-message
      :active.sync="showHelp"
      :title="$t('Using the Resource Location Assigner')"
      style="padding-top: 10px"
      class="is-info"
    >
      <span v-html="$t('Resource Location Assigner Help')"></span>
    </b-message>

    <file-loader
      v-if="!error"
      :url="loaderURL"
      :customer="customer"
      :emit-type="emitType"
      :environment="environment"
      @upload-complete="isLoading = false"
      @upload-started="uploadStarted()"
    />
    <span v-if="error">{{ error }}</span>
    <br />
    <a
      :href="csvTemplate"
      download="resource-location-assignment-template.csv"
      class="has-text-link"
      >{{ $t('Download CSV Template') }}</a
    >
    <div v-if="retrieved">
      <p>{{ $t('Successful') }}: {{ successList.length }}</p>
      <p>{{ $t('Errors') }}: {{ errorList.length }}</p>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import FileLoader from './FileLoader';
import { eventBus } from '@/events/events';
import ResultTable from './ResultTable';

export default defineComponent({
  name: 'ResourceLocationAssigner',
  components: {
    FileLoader,
    ResultTables: ResultTable,
  },
  props: {
    environment: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      emitType: 'resourceLocationAssignResults',
      showHelp: true,
      retrieved: false,
      error: null,
      idLabelMap: null,
      errorList: [],
      successList: [],
      successColumns: [
        {
          field: 'resourceId',
          label: 'Resource ID',
          numeric: false,
          sortable: true,
        },
        {
          field: 'generalError',
          label: 'Error',
          numeric: false,
          sortable: true,
        },
        {
          field: 'missingLocations',
          label: 'Missing Locations',
          numeric: false,
          sortable: true,
        },
        {
          field: 'mon',
          label: 'Mon',
          numeric: false,
          sortable: true,
        },
        {
          field: 'tue',
          label: 'Tue',
          numeric: false,
          sortable: true,
        },
        {
          field: 'wed',
          label: 'Wed',
          numeric: false,
          sortable: true,
        },
        {
          field: 'thu',
          label: 'Thu',
          numeric: false,
          sortable: true,
        },
        {
          field: 'fri',
          label: 'Fri',
          numeric: false,
          sortable: true,
        },
        {
          field: 'sat',
          label: 'Sat',
          numeric: false,
          sortable: true,
        },
        {
          field: 'sun',
          label: 'Sun',
          numeric: false,
          sortable: true,
        },
      ],
      errorColumns: [
        {
          field: 'statusCode',
          label: 'Status Code',
          width: '40',
          numeric: true,
        },
        {
          field: 'request',
          label: 'Request',
          width: '100',
          numeric: false,
        },
        {
          field: 'error',
          label: 'Error',
          width: '100',
          numeric: false,
        },
        {
          field: 'body',
          label: 'Content',
          width: '100',
          numeric: false,
        },
      ],
    };
  },
  computed: {
    loaderURL() {
      return this.getURL();
    },
    csvTemplate() {
      return require('@/assets/csv/AssignResourceLocationsTemplate.csv')
        .default;
    },
  },
  created() {
    eventBus.on(this.emitType, (data) => {
      this.retrieved = true;
      this.successList = this.processResults(data);
      this.errorList = this.getErrors(data);
      this.showHelp = false;
    });
  },
  beforeDestroy() {
    eventBus.off(this.emitType);
  },
  mounted() {
    while (this.successList.length > 0) {
      this.successList.pop();
    }
    while (this.errorList > 0) {
      this.errorList.pop();
    }
  },
  methods: {
    uploadStarted() {
      this.isLoading = true;
      this.emptyArray(this.successList);
      this.emptyArray(this.errorList);
    },
    getErrors(results) {
      let returnArray = [];
      // Pull out all the errors using filter.
      // Then push the errors into a separate array to return
      results
        .filter((line) => {
          if (line.error) {
            return true;
          } else {
            return false;
          }
        })
        .forEach((line) => {
          returnArray.push({
            statusCode: line.error.statusCode || 400,
            error: line.generalError || line.error.error.detail,
            body:
              line.generalError ||
              JSON.stringify(line.error.options.body, null, 2),
            request:
              line.assignedLocationsResult ||
              line.error.response.request.uri.href,
          });
        });
      return returnArray;
    },
    getLocationString(type, value) {
      let output;
      switch (type) {
        case 'S': {
          if (value) {
            output = `S:${this.idLabelMap.get(value)}`;
          } else {
            output = ``;
          }
          return output;
        }
        case 'E': {
          if (value) {
            output = `E:${this.idLabelMap.get(value)}`;
          } else {
            output = ``;
          }
          return output;
        }
        case 'H': {
          if (value) {
            output = `H:${this.idLabelMap.get(value)}`;
          } else {
            output = ``;
          }
          return output;
        }
      }
    },
    convertAssignResultToStrings(result) {
      let returnVal = {};
      if (result.mon) {
        let monString = `${this.getLocationString(
          'S',
          result.mon.start,
        )} ${this.getLocationString(
          'E',
          result.mon.end,
        )} ${this.getLocationString('H', result.mon.homeZoneCenter)}`;
        returnVal.mon = monString || 'Failed';
      }
      if (result.tue) {
        let tueString = `${this.getLocationString(
          'S',
          result.tue.start,
        )} ${this.getLocationString(
          'E',
          result.tue.end,
        )} ${this.getLocationString('H', result.tue.homeZoneCenter)}`;
        returnVal.tue = tueString;
      }
      if (result.wed) {
        let wedString = `${this.getLocationString(
          'S',
          result.wed.start,
        )} ${this.getLocationString(
          'E',
          result.wed.end,
        )} ${this.getLocationString('H', result.wed.homeZoneCenter)}`;
        returnVal.wed = wedString;
      }
      if (result.thu) {
        let thuString = `${this.getLocationString(
          'S',
          result.thu.start,
        )} ${this.getLocationString(
          'E',
          result.thu.end,
        )} ${this.getLocationString('H', result.thu.homeZoneCenter)}`;
        returnVal.thu = thuString;
      }
      if (result.fri) {
        let friString = `${this.getLocationString(
          'S',
          result.fri.start,
        )} ${this.getLocationString(
          'E',
          result.fri.end,
        )} ${this.getLocationString('H', result.fri.homeZoneCenter)}`;
        returnVal.fri = friString;
      }
      if (result.sat) {
        let satString = `${this.getLocationString(
          'S',
          result.sat.start,
        )} ${this.getLocationString(
          'E',
          result.sat.end,
        )} ${this.getLocationString('H', result.sat.homeZoneCenter)}`;
        returnVal.sat = satString;
      }
      if (result.sun) {
        let sunString = `${this.getLocationString(
          'S',
          result.sun.start,
        )} ${this.getLocationString(
          'E',
          result.sun.end,
        )} ${this.getLocationString('H', result.sun.homeZoneCenter)}`;
        returnVal.sun = sunString;
      }
      return returnVal;
    },
    processResults(data) {
      this.idLabelMap = new Map();
      let results = [];
      // Reshape results for display
      data.forEach((entry) => {
        let rowMap = new Map(entry.locationMap);
        if (rowMap && rowMap.size > 0) {
          this.idLabelMap = new Map([...rowMap, ...this.idLabelMap]);
        }
        let resultDisplayObject = this.convertAssignResultToStrings(
          entry.assignLocationsResult.result,
        );
        let resourceId = entry.assignLocationsResult.row.resourceId;
        resultDisplayObject.resourceId = resourceId;
        resultDisplayObject.missingLocations =
          entry.missingLocations.toString();
        resultDisplayObject.generalError = entry.generalError;
        results.push(resultDisplayObject);
      });
      return results;
    },
    getURL() {
      const baseURL = this.$configData.dataLoaders.baseURL;
      const path = this.$configData.dataLoaders.resourceLocationAssigner.path;
      if (!baseURL || !path) {
        this.error = 'No configuration found for loader';
        return null;
      } else {
        return baseURL + path;
      }
    },
  },
});
</script>
